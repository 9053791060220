import { AppBoundaries } from '../../modules/shared/types/appBoundaries';
import { PublicPropertiesStore } from '../../modules/shared/types/publicMethods';
import { messageTypeEnum } from '../postMessageWrapper';

export default ({
  element,
  publicPropertiesStore,
  offsetX,
  offsetY,
  anchorPosition,
  forceWidth,
}: {
  element: Element;
  publicPropertiesStore: PublicPropertiesStore;
  offsetX: number;
  offsetY: number;
  anchorPosition: 'left' | 'right';
  forceWidth?: string;
}) => {
  /*___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  if (!('ResizeObserver' in window)) {
    return;
  }
  const ro = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    const lastEntry = entries.pop();
    if (lastEntry) {
      const { width, height } = lastEntry.contentRect;

      window.parent.postMessage(
        {
          type: messageTypeEnum.RESIZE,
          payload: {
            //________________________________________________________________
            iframeId: (window as any).iframeId,
            size: {
              width: forceWidth || Math.round(width),
              height: Math.round(height),
            },
          },
        },
        '*',
      );

      const appBoundaries = {
        [anchorPosition]: offsetX,
        bottom: offsetY,
        width,
        height,
      } as unknown as AppBoundaries;

      publicPropertiesStore.dispatch('app:boundaries', appBoundaries);
    }
  });

  ro.observe(element);

  return () => ro.unobserve(element);
};
