import { Component } from 'react';
import { SentryLauncher } from '../utils/sentry/sentryLauncher';

type Props = {
  getIsSentryLoaded: SentryLauncher['getIsSentryLoaded'];
  sentryCatchCustomErrors: SentryLauncher['sentryCatchCustomErrors'];
};

export class ErrorBoundary extends Component<Props> {
  componentDidCatch(error: Error) {
    const { getIsSentryLoaded, sentryCatchCustomErrors } = this.props;
    if (!process.env.PRODUCTION) {
      //________________________________________
      console.error(error);
    }
    if (getIsSentryLoaded()) {
      sentryCatchCustomErrors(error);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
