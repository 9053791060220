/*_________________________________________________*/
import React, { useEffect, useMemo, useState } from 'react';

import { Channel } from '@iadvize/notifications-library/esm/constants';
import { EmbeddedConversationStarterTemplate } from '@iadvize/notifications-library/esm/entities/notification';
import { useExternalFont } from '@iadvize/notifications-library/esm/NotificationTemplate/fontHelper';
import usePerformanceTracking from './usePerformanceTracking';
import { PerformanceMarks } from '../../../../lib/performance';
import { ChatboxTemplate } from '../../../shared/types/chatboxTemplate';
import {
  EngagementAcceptedOptionalActionParameters,
  NotificationData,
  NotificationOrchestratorEmitter,
} from '../../../notificationOrchestrator/types';
import { NotificationIframeGlobals } from '../../../notificationOrchestrator/globalTypes';
import lazyNotificationMap from './lazyNotificationMap';

type Props = {
  chatboxTemplate: ChatboxTemplate;
  isInitiallyMinimized: boolean;
  lang: string;
  media: string;
  notificationData: NotificationData;
  notificationOrchestratorEmitter: NotificationOrchestratorEmitter;
  ruleId: number;
  onEngagementAccepted: (
    channel: Channel,
    optionalActionParameters: EngagementAcceptedOptionalActionParameters,
  ) => void;
  onOpen: () => void;
};

const {
  translationService,
  isConversationStarted: initialIsConversationStarted,
} = window as unknown as NotificationIframeGlobals;

const translations = {
  minimizeButtonLabel: translationService(
    'livechat.templatedNotification.minimizeButton.label',
  ),
  maximizeButtonLabel: translationService(
    'livechat.templatedNotification.maximizeButtonLabel.label',
  ),
  closeButtonLabel: translationService(
    'livechat.templatedNotification.closeButtonLabel.label',
  ),
  closeFooterButtonLabel: translationService(
    'livechat.templatedNotification.invitation.footerCloseButton.label',
  ),
  resumeConversationButtonText: translationService(
    'livechat.templatedNotification.embedded-conversation-starters.resume.label',
  ),
  starterButtonDescription: translationService(
    'livechat.templatedNotification.embedded-conversation-starters.button.description',
  ),
};

export default ({
  chatboxTemplate,
  isInitiallyMinimized,
  lang,
  media,
  notificationData,
  notificationOrchestratorEmitter,
  ruleId,
  onEngagementAccepted,
  onOpen,
}: Props) => {
  const { allowVisitorCamera, disableVisitorCameraByDefault } =
    chatboxTemplate.templateAttributes;

  const shouldDisplayCameraDisabledTip =
    allowVisitorCamera && !disableVisitorCameraByDefault;

  usePerformanceTracking(PerformanceMarks.NOTIFICATION_DISPLAY);

  const notificationWithRuleId = useMemo(
    () => ({
      ...notificationData,
      ruleId,
    }),
    [notificationData, ruleId],
  );

  const [isMinimized, setIsMinimized] = useState(isInitiallyMinimized);

  const { notification, parameters: actionParameters } = notificationData;
  const { templateType, mobileTemplateType } = notification;

  const currentTemplateType =
    media === 'MOBILE' && templateType !== 'EMBEDDED_CONVERSATION_STARTER'
      ? mobileTemplateType
      : templateType;

  const Notification = lazyNotificationMap[currentTemplateType];

  const [isConversationStarted, setIsConversationStarted] = useState(
    initialIsConversationStarted,
  );

  useEffect(() => {
    const setToTrue = () => setIsConversationStarted(true);
    const setToFalse = () => setIsConversationStarted(false);
    notificationOrchestratorEmitter.on('engagementAccepted', setToTrue);
    notificationOrchestratorEmitter.on('showNotification', setToFalse);

    return () => {
      notificationOrchestratorEmitter.off('engagementAccepted', setToTrue);
      notificationOrchestratorEmitter.off('showNotification', setToFalse);
    };
  }, [notificationOrchestratorEmitter]);

  const props = useMemo(
    () => ({
      ...notificationData,
      allowVisitorCamera: shouldDisplayCameraDisabledTip,
      actionParameters,
      templateType,
      mobileTemplateType:
        notification.templateType === 'EMBEDDED_CONVERSATION_STARTER'
          ? notification.templateType
          : notification.mobileTemplateType,
      language: lang.toUpperCase(),
      media,
      isMinimized,
      onEngagementAccepted,
      onOpen,
      onMinimize: () => {
        notificationOrchestratorEmitter.emit(
          'minimizeNotification',
          notificationWithRuleId,
        );
        setIsMinimized(true);
      },
      onMaximize: () => {
        notificationOrchestratorEmitter.emit(
          'maximizeNotification',
          notificationWithRuleId,
        );
        setIsMinimized(false);
      },
      onClose: () =>
        notificationOrchestratorEmitter.emit(
          'closeNotification',
          notificationWithRuleId,
        ),
      translations,
      isConversationStarted,
    }),
    [
      notificationData,
      shouldDisplayCameraDisabledTip,
      actionParameters,
      templateType,
      notification.templateType,
      notification.mobileTemplateType,
      lang,
      media,
      isMinimized,
      onEngagementAccepted,
      onOpen,
      isConversationStarted,
      notificationOrchestratorEmitter,
      notificationWithRuleId,
    ],
  );

  const ecsTemplateAttributes = (
    notificationData.notification as EmbeddedConversationStarterTemplate
  ).templateAttributes;

  const { customFontFamily, fontFamily, fontUrl } = ecsTemplateAttributes;
  useExternalFont(fontUrl, fontFamily, customFontFamily);

  return (
    <React.Suspense fallback={null}>
      {/*_______________________________________*/}
      <Notification {...props} />
    </React.Suspense>
  );
};
