import { useEffect } from 'react';
import {
  endPerformanceMeasure,
  PerformanceMark,
} from '../../../../lib/performance';

//_____________________________________________________________________________________________
//___________________________________________________________________________________
let hasBeenMountedAtLeastOnce = false;

export default (markName: PerformanceMark) => {
  useEffect(() => {
    if (hasBeenMountedAtLeastOnce) {
      return;
    }

    endPerformanceMeasure(markName);

    hasBeenMountedAtLeastOnce = true;
  }, [markName]);
};
