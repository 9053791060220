import React, { useCallback, useEffect, useMemo } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import {
  Position,
  WithMobilePositionEnabled,
} from '@iadvize/notifications-library/esm/entities/position';
import { NotificationTemplate } from '@iadvize/notifications-library/esm/entities/notification';
import { isClassicNotificationTemplate } from './globals';
import NotificationContainer from './NotificationContainer';
import IframeAutoResize from '../../../lib/IframeManager/IframeAutoResize';
import { ErrorBoundary } from '../../shared/components/ErrorBoundary';
import { NotificationIframeGlobals } from '../../notificationOrchestrator/globalTypes';
import { isNotificationMinimizedFlagPrefix } from '../../notificationOrchestrator/constants';
import { InputDeviceTypes } from '../../shared/InputDeviceTypes';

const {
  appContainerId,
  chatboxTemplate,
  deviceType,
  notificationData,
  publicPropertiesStore,
  ruleId,
  sentryLauncher: { getIsSentryLoaded, sentryCatchCustomErrors },
  onCurrentInputModeChange,
} = window as unknown as NotificationIframeGlobals;

const appNode = document.getElementById(appContainerId);
const { templateType, mobileTemplateType } = notificationData.notification;
const isDesktop = deviceType === 'desktop';
const appliedTemplateType =
  isDesktop || templateType === 'EMBEDDED_CONVERSATION_STARTER'
    ? templateType
    : mobileTemplateType;

const templateAttributes = notificationData.notification
  .templateAttributes as NotificationTemplate['templateAttributes'] &
  WithMobilePositionEnabled;

const { position: desktopPosition, mobilePosition } = templateAttributes;

const defaultPosition: Position = desktopPosition || {
  anchorPosition: '',
  offsetX: 0,
  offsetY: 0,
};

const position =
  isDesktop || appliedTemplateType === 'BADGE'
    ? defaultPosition
    : mobilePosition || defaultPosition;

const { anchorPosition } = position;

IframeAutoResize({
  element: document.body.children[0],
  publicPropertiesStore,
  //___________________________________________________________________________________________________________________________________________
  offsetX: position.offsetX - 12,
  offsetY: position.offsetY - 12,
  anchorPosition: anchorPosition === 'CORNER_BOTTOM_RIGHT' ? 'right' : 'left',
  forceWidth:
    notificationData.parameters.type === 'EMBEDDED_CONVERSATION_STARTER'
      ? '100%'
      : undefined,
});

if (window.document?.body) {
  window.document.body.style.justifyContent =
    anchorPosition === 'CORNER_BOTTOM_RIGHT' ? 'flex-end' : 'flex-start';
}

if (!appNode) {
  throw new Error(
    'The notification app could not be mounted (missing root DOM element)',
  );
}
appNode.className = `${appliedTemplateType.toLowerCase()} ${anchorPosition}`;

//___________________________________________________________
const isMinimized =
  localStorage.getItem(isNotificationMinimizedFlagPrefix) === 'true';

const NotificationApp = () => {
  const unmountRootNode = () => {
    unmountComponentAtNode(appNode);
    appNode.remove();
  };

  useEffect(() => {
    appNode.addEventListener('unmount', unmountRootNode);
    return () => appNode.removeEventListener('unmount', unmountRootNode);
  });

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.code === 'Tab') {
      onCurrentInputModeChange(InputDeviceTypes.keyboard);
    }
  }, []);

  const handleMousePress = useCallback(() => {
    onCurrentInputModeChange(InputDeviceTypes.mouse);
  }, []);

  useEffect(() => {
    const node = appNode;
    if (node) {
      node.addEventListener('keydown', handleKeyPress);
      node.addEventListener('mousedown', handleMousePress);
    }
    return () => {
      if (node) {
        node.removeEventListener('keydown', handleKeyPress);
        node.removeEventListener('mousedown', handleMousePress);
      }
    };
  }, [handleKeyPress, handleMousePress]);

  const isInitiallyMinimized = useMemo(
    () =>
      isClassicNotificationTemplate(templateAttributes)
        ? templateAttributes.canBeMinimized && isMinimized
        : isMinimized,
    [],
  );

  return (
    <ErrorBoundary
      getIsSentryLoaded={getIsSentryLoaded}
      sentryCatchCustomErrors={sentryCatchCustomErrors}
    >
      <NotificationContainer
        chatboxTemplate={chatboxTemplate}
        isInitiallyMinimized={isInitiallyMinimized}
        notificationData={notificationData}
        ruleId={ruleId}
      />
    </ErrorBoundary>
  );
};

render(<NotificationApp />, appNode);
