import React from 'react';
import { NotificationTemplateType } from '@iadvize/notifications-library/esm/entities/notification';
import { getStaticUrl } from '../../../shared/staticUrl';

//_______________________________________
__webpack_public_path__ = getStaticUrl();

const BadgeNotification = React.lazy(
  () =>
    import(
      /*_____________________________________________*/
      '@iadvize/notifications-library/esm/BadgeNotification/BadgeNotification'
    ),
);
const MessagingNotification = React.lazy(
  () =>
    import(
      /*_________________________________________________*/
      '@iadvize/notifications-library/esm/MessagingNotification/MessagingNotification'
    ),
);
const MiniBadgeNotification = React.lazy(
  () =>
    import(
      /*_________________________________________________*/
      '@iadvize/notifications-library/esm/MiniBadgeNotification/MiniBadgeNotification'
    ),
);
const ClassicNotification = React.lazy(
  () =>
    import(
      /*_______________________________________________*/
      '@iadvize/notifications-library/esm/ClassicNotification/ClassicNotification'
    ),
);
const InvitationNotification = React.lazy(
  () =>
    import(
      /*__________________________________________________*/
      '@iadvize/notifications-library/esm/InvitationNotification/InvitationNotification'
    ),
);

const EmbeddedConversationStarterNotification = React.lazy(
  () =>
    import(
      /*___________________________________________________________________*/
      '@iadvize/notifications-library/esm/EmbeddedConversationStarter/EmbeddedConversationStarter'
    ),
);

const lazyNotificationMap: Record<NotificationTemplateType, React.ReactNode> = {
  BADGE: BadgeNotification,
  MESSAGING: MessagingNotification,
  MINI_BADGE: MiniBadgeNotification,
  CLASSIC: ClassicNotification,
  INVITATION: InvitationNotification,
  EMBEDDED_CONVERSATION_STARTER: EmbeddedConversationStarterNotification,
  FIXED: null,
};

export default lazyNotificationMap;
