import React from 'react';
import { NotificationIframeGlobals } from '../../notificationOrchestrator/globalTypes';
import { NotificationData } from '../../notificationOrchestrator/types';
import { ChatboxTemplate } from '../../shared/types/chatboxTemplate';
import deviceTypeToMediaType from '../../shared/utils/deviceTypeToMediaType';
import { Notification } from './components';

type Props = {
  chatboxTemplate: ChatboxTemplate;
  isInitiallyMinimized: boolean;
  notificationData: NotificationData;
  ruleId: number;
};

export default ({
  chatboxTemplate,
  isInitiallyMinimized,
  notificationData,
  ruleId,
}: Props) => {
  const {
    curlang,
    deviceType,
    notificationOrchestratorEmitter,
    onOpen,
    onEngagementAccepted,
  } = window as unknown as NotificationIframeGlobals;

  const media = deviceTypeToMediaType(deviceType);

  //_______________________________________________
  //___________________________________________________________________________
  if (notificationData === undefined) {
    return null;
  }

  return (
    <Notification
      chatboxTemplate={chatboxTemplate}
      isInitiallyMinimized={isInitiallyMinimized}
      lang={curlang}
      media={media}
      notificationData={notificationData}
      notificationOrchestratorEmitter={notificationOrchestratorEmitter}
      ruleId={ruleId}
      onEngagementAccepted={onEngagementAccepted}
      onOpen={onOpen}
    />
  );
};
