import {
  ClassicNotificationTemplate,
  NotificationTemplate,
} from '@iadvize/notifications-library/esm/entities/notification';
import { NotificationIframeGlobals } from '../../notificationOrchestrator/globalTypes';

export const getTranslate = () =>
  (window as unknown as NotificationIframeGlobals).translationService;

export const isClassicNotificationTemplate = (
  notificationTemplate: NotificationTemplate['templateAttributes'],
): notificationTemplate is ClassicNotificationTemplate['templateAttributes'] =>
  'canBeMinimized' in notificationTemplate;
